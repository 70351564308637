/**
 * 示例-自定义组件-图表
 * luxinwen
 * 2023-04
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-chart 图表</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <Row>
            <Col :span="8">
              <sp-chart v-model="value" type="line" :data="chartData" :loading="loading"></sp-chart>
            </Col>
            <Col :span="8">
              <sp-chart v-model="value" type="bar" :data="chartData" :loading="loading"></sp-chart>
            </Col>
            <Col :span="8">
              <sp-chart v-model="value" type="pie" :data="pieData" :loading="loading"></sp-chart>
            </Col>
          </Row>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-chart v-model="value" type="line" :data="chartData"&gt;&lt;/sp-chart&gt;</pre>
          <p>设置属性 <span class="text-red">v-model</span> 或 <span class="text-red">value</span> 可传递图表配置参数，具体参考 echarts api 文档。</p>
          <p>设置属性 <span class="text-red">type</span>，可改变图表类型，如 line / bar / pie 等。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: 'echarts配置参数，可以使用 v-model 双向绑定数据',
            type: 'Object',
            default: '{}'
          },
          'type': {
            tips: '图表类型，line / bar / pie 等',
            type: '[String, Array]',
            default: ''
          },
          'data': {
            tips: '图表数据',
            type: 'Array',
            default: '[]'
          },
          'width': {
            tips: '图层宽度',
            type: 'String',
            default: '100%'
          },
          'height': {
            tips: '图层高度',
            type: 'String',
            default: '400px'
          },
          'loading': {
            tips: '是否加载中',
            type: 'Boolean',
            default: 'false'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        value: {},
        loading: false,
        chartData: [],
        pieData: []
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    },
    mounted() {
      this.setChart();
    },
    methods: {
      /**
       * 改变图表数据
       */
      setChart() {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.chartData = [230, 224, 218, 135, 147, 260, 100];
          this.pieData = [
            { value: 1048, name: 'Search Engine' },
            { value: 735, name: 'Direct' },
            { value: 580, name: 'Email' },
            { value: 484, name: 'Union Ads' },
            { value: 300, name: 'Video Ads' }
          ];
        }, 1500);
      }
    }
  };
</script>